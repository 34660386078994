.pointerTitle {
    cursor: pointer;
    color: green;
}

.accordion-collapse.collapse {
    /*border-style:solid;*/
    box-shadow: 0 0 50px rgba(113, 126, 121, 40%);
    border-radius: 0 0 16px 16px;
    margin-top: 0%;
    /*margin-top: -1%;*/
    padding-top: 0%;
}

.accordion-item {
    box-shadow: 0 0 50px rgba(113, 126, 121, 10%);
    border-radius: 16px;
}

.subtitle {
    font-size: 30pt;
}